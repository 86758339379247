@import '../../styles/config.scss';

header {
    // height: 140px;
    // margin: 40px 70px 0;
    // position: absolute;
    min-height: 180px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    // top: 0;

    .container {
        padding: 30px 20px 10px 20px;
        @include for-desktop-up {
            padding: 50px 50px 10px 60px;
        }
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        // position: fixed !important;
        display: flex;
        flex-basis: 100%;
        align-items: flex-end;
        margin-top: auto;
        align-items: baseline;
        transition: all 0.4s;

        .logo {
            display: inline-block;
            margin-right: auto;
        }

        nav {
            display: inline-block;
            flex-direction: row;
            margin-right: auto;

            a {
                font-size: 22px;
                text-transform: uppercase;
                margin: 0 10px;
                padding: 5px 10px;

                &:hover {
                    color: $brand-primary;
                }
            }
        }

        .social {
            display: none;
            @include for-desktop-up {
                display: inline-flex;
            }
        }

        .subNav {
            display: flex;
            flex-direction: row;
        }
    }
}
