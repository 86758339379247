@import '../../../styles/config.scss';

// 
@mixin outerShape($size: 90px, $color: #263035) {
    &:before {
        position: absolute;
        content: '';
        width: $size;
        height: 90%;
        top: 5%;
        left: -5px;
        border-left: 1px solid $color;
        transition: width 0.6s;
        border-right: 1px solid $color;
    }
    &:after {
        position: absolute;
        content: '';
        height: $size;
        width: 90%;
        top: -5px;
        left: 5px;
        border-top: 1px solid $color;
        border-bottom: 1px solid $color;
    }
}

.diamondShape {
    background: #19232f;
    height: 35px;
    text-align: center;
    transform: rotate(45deg);
    width: 35px;
    margin-left: 25px;
    transition: background-color 150ms ease-out;
    display: inline-block;

    &.playButton {
        background: rgba(#d0d3d3, .85);
        height: 100px;
        width: 100px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotate(45deg) translate(-50%, -50%);
        transform-origin: 0% 0%;
        
        @include outerShape(110px, rgba(#d0d3d3, .5));

        > .inner {
            width: 0;
            height: 0;
            border-top: 18px solid transparent;
            border-left: 30px solid #bf1f55;
            border-bottom: 18px solid transparent;
        }
    }

    &.goldBig {
        background: $brand-gold;
        height: 80px;
        text-align: center;
        transform: rotate(45deg);
        width: 80px;
        // margin-left: 55px;
        // transition: background-color 150ms ease-out;
        display: inline-block;

        @include outerShape;

        svg {
            font-size: 40px;
        }

        .inner {
            color: #19232f;
            display: table-cell;
            height: 80px;
            transform: rotate(-45deg);
            vertical-align: middle;
            width: 80px;
        }
    }

    &:first-child {
        margin-left: 0;
    }

    .inner {
        color: #19232f;
        display: table-cell;
        height: 35px;
        transform: rotate(-45deg);
        vertical-align: middle;
        width: 35px;
    }

    &.link {
        &:hover {
            cursor: pointer;
            svg {
                color: #fff;
            }
        }

        &.facebook:hover {
            background-color: $social-facebook;
            &:before,
            &:after {
                border-bottom-color: $social-facebook;
                border-top-color: $social-facebook;
            }
        }

        &.twitter:hover {
            background-color: $social-twitter;
            &:before,
            &:after {
                border-bottom-color: $social-twitter;
                border-top-color: $social-twitter;
            }
        }

        &.youtube:hover {
            background-color: $social-youtube;
            &:before,
            &:after {
                border-bottom-color: $social-youtube;
                border-top-color: $social-youtube;
            }
        }

        &.instagram:hover {
            background-color: $social-instagram;
            &:before,
            &:after {
                border-bottom-color: $social-instagram;
                border-top-color: $social-instagram;
            }
        }

        &.discord:hover {
            background-color: $social-discord;
            &:before,
            &:after {
                border-bottom-color: $social-discord;
                border-top-color: $social-discord;
            }
        }
    }
}
