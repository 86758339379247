@import '~styles/variables';

.ui.form {
    .field > label {
        text-transform: uppercase;
        font-size: 18px;
        color: white;
    }

    input {
        color: #6b6d6e !important;
        padding: 0.5rem;
        font-size: 18px;
        width: 100%;
        display: block;
        border-radius: 4px;
        border: 1px solid #ccc;
        margin-bottom: 20px;
    }

    input:focus {
        border-color: #007eff;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 0 3px rgba(0, 126, 255, 0.1);
        outline: none;
    }

    input.error {
        border-color: $brand-error;
    }

    label {
        text-transform: uppercase;
        font-size: 20px;
        display: block;
        color: #6b6d6e;
    }

    .checkbox {
        label {
            font-size: 14px;
            text-transform: inherit !important;
            color: #6b6d6e !important;
        }
    }

    .input-feedback {
        color: $brand-error;
        margin-top: 0.25rem;
        text-align: right;
    }

    .input-feedback-empty {
        height: 21px;
    }

    button:disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
    }

    button + button {
        margin-left: 0.5rem;
    }

    button.outline {
        background-color: #eee;
        border: 1px solid #aaa;
        color: #555;
    }

    .transparent {
        height: 50px;
        border-bottom: 1px solid #6b6d6e;
    }
}
