@import '../../styles/config.scss';

.container {
    width: 100%;
    padding: 0 15px;

    @media screen and (min-width: 1330px) {
        width: 1310px;
        max-width: 1310px;
        margin: 0 auto;
    }

    .full {
        max-width: none;
    }
}

.wide {
    max-width: 1920px;
    padding: 0;
}
