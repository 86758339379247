@import '~styles/variables';

.button {
    background-color: $brand-primary;
    color: #fff;
    font-size: 22px;
    font-family: $font-regular;
    text-transform: uppercase;
    padding: 10px;
    text-align: center;
    position: relative;
    letter-spacing: 0.075em;

    &.premium {
        background-color: $brand-gold;

        &:after, &:before, .boxShadow {
            background-color: $brand-gold !important;
        }

        .boxShadow:after {
            background-color: $brand-gold;
            box-shadow: 0px -2px 0px 5px $brand-gold; 
        }
    }

    &:before, &:after {
        content: "";
        background-color: $brand-primary;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        transform-origin: top center;
        z-index: 1;
        transition: .3s ease-in-out;
    }

    &:before {
        left: 0;
        transform: skewX(-30deg);
    }

    &:after {
        right: 0;
        transform: skewX(30deg);
    }

    .buttonText {
        position: relative;
        z-index: 2;
    }

    .boxShadow {
        position: absolute;
        left: 0px;
        width: 100%;
        height: 94%;
        top: -0.5px;
        background: $brand-primary;
        transform: scale(1.15) perspective(82px) rotateX(30deg);
        box-shadow: 0px -2px 0px 5px rgba(#fff, .15);
        transition: all 900ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            background-color: $brand-primary;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0px -2px 0px 5px $brand-primary;
            opacity: 0;
            transition: opacity 900ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }

    svg {
        opacity: .5;
        margin-left: 10px;
    }
}

.button:hover {
    .buttonText {
        color: #fff;
    }

    .boxShadow:after {
        opacity: 1
    }
}
