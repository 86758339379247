// @import './reset';
@import './mixins';

// Global styles

html,
body {
    // height: 100%;
    // overflow-x: hidden;
    box-sizing: border-box;
    line-height: 1.5;
    color: #fff;

    @include font-light;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include font-light;
        font-weight: normal;
        text-transform: uppercase;
    }

    h1,
    h2 {
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        letter-spacing: 0.03em;
    }

    h2 {
        font-size: 36px;
    }

    h3 {
        letter-spacing: 0.03em;
    }

    p {
        @include for-phone-only {
            font-size: 18px;
        }
        font-size: 20px;
        letter-spacing: 0.03em;

        a {
            color: $brand-primary;

            &:hover {
                color: #ef5186;
            }
        }
    }

    ol,
    ul {
        list-style: none;
        margin: 0;
    }

    a {
        color: #fff;
    }
}

// TODO: maybe reset default table styles
table {
    th {
        font-weight: normal;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

html {
    height: 100%;
    overflow: auto;
}
body {
    height: 100%;
    overflow-x: unset;
}

// @include viewport(mobileMax) {
//     html,
//     body {
//         h2 {
//             font-size: 28px;
//         }

//         p {
//             font-size: 18px;
//         }
//     }
// }

// @include viewport(xsMax) {
//     html,
//     body {
//         h2 {
//             font-size: 22px;
//         }

//         p {
//             font-size: 18px;
//         }
//     }
// }
