:global {
    .visibility {
        transition: all 800ms cubic-bezier(0.23, 1, 0.32, 1);
        width: 100%;
        height: 100%;
    }
    
    .inVisible {
        opacity: 0;
    }
    
    .fadeUp {
        transform: translateY(40px);
    }

    .fadeRight {
        transform: translateX(-40px);
    }

    .isVisible {
        opacity: 1;
        transform: translate(0);
    }

    .delay {
        transition-delay: .25s;
    }
}