$brand-primary: #ea2667;
$brand-gold: #c7a23e;
$brand-error: red;

// Fonts
$font-thin: 'NeusaNextPro-CompactThin';
$font-light: 'NeusaNextPro-CompactLight';
$font-regular: 'NeusaNextPro-CompactRegular';
$font-light-italic: 'NeusaNextPro-CompactLightItalic';
$font-regular-italic: 'NeusaNextPro-CompactItalic';
$font-medium: 'NeusaNextPro-CompactMedium';
$font-bold: 'NeusaNextPro-CompactBold';

// Social colors
$social-facebook: #34579b;
$social-twitter: #2faae1;
$social-youtube: #da4a38;
$social-instagram: #833ab4;
$social-vk: #45668e;
$social-discord: #7785d9;

// Custom break points (Max)
$max-1290: 1290px;
