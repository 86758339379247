@import '~styles/config';

//TODO: Add hover effect

.squareButton {
    background: $brand-primary;
    color: #fff;
    font-size: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: 100%;
    border-radius: 0;
    border: 0;
    padding: 18px 0;
    cursor: pointer;

    @include font-regular;
}
