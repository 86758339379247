@import '../../styles/config';

.newsGrid {
    display: grid;
    grid-template: 1fr / 1fr;
    grid-row-gap: 60px;
    grid-column-gap: 30px;

    @media screen and (min-width: 780px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 1290px) {
        // grid-template: auto auto auto / 1fr 1fr 1fr;
        grid-template-columns: repeat(3, 1fr);
    }
}

.newsDetailGrid {
    display: grid;
    grid-template: 1fr / 1fr;
    grid-row-gap: 60px;
    grid-column-gap: 30px;

    @media screen and (min-width: 780px) {
        grid-template-columns: repeat(1fr);
    }

    @media screen and (min-width: 1290px) {
        grid-template: auto auto / 880px 1fr;
        // grid-template-columns: repeat(3, 1fr);
    }
}
