.modal {
    .content {
        font-size: 16px;
        color: black;
    }
}

span.link {
    cursor: pointer;
}
