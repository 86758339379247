@import '../../styles/config';

.previewItem {
    .previewImage {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 56.25%;
    }
    h3 {
        @include font-regular;
        font-size: 24px;
    }
    p {
        @include font-light;
        font-size: 20px;
    }
}

.newsDetail {
    .featureImg {
        display: flex;
        width: 100%;
        overflow: hidden;
        align-items: flex-end;
        position: relative;
        padding-bottom: 30px;
    }
    h2 {
        margin-bottom: 10px;
    }
    .date {
        @include font-regular;
        color: #a6a6a6;
        margin-bottom: 20px;
    }

    ul {
        margin-bottom: 20px;
        li {
            list-style: disc;
            font-size: 20px;
        }
    }
}
