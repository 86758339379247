@import '../../styles/config';

h1 {
    margin: 20px 0;
}

.titleWrapper h1 {
    @include container();
    font-size: 60px;
}

.prodWrap {
    display: grid;
    grid-template: 1fr / 1fr;
    @include for-tablet-landscape-up {
        grid-template: 1fr / 1fr 1fr;
    }
    margin-top: 190px;
    grid-column-gap: 20px;

    .deco {
        // background-color: purple;
        background: url('../../static/prod1-deco.jpg') no-repeat 50% 50%;
        background-size: cover;
        height: 140px;
        @include for-tablet-landscape-up {
            height: 340px;
        }
        position: relative;

        &:after {
            content: '';
            position: absolute;
            height: 250px;
            width: 350px;
            @include for-tablet-landscape-up {
                height: 509px;
                width: 411px;
            }

            background: url('../../static/prod2-robot.png') no-repeat bottom
                left;
            background-size: contain;
            bottom: 0px;
            right: 30%;
        }
    }

    .detail {
        margin-top: 40px;
        margin-bottom: 80px;
        h3 {
            @include font-light;
            text-transform: uppercase;
            font-size: 32px;
        }

        p {
            font-size: 20px;

            @include for-tablet-landscape-up {
                font-size: 24px;
                padding-right: 60px;
            }
        }
    }

    .buy {
        position: absolute;
        bottom: 45px;
        right: 60px;
    }

    .prod1 {
        display: grid;
        grid-template: 1fr / 1fr 645px;
        .deco {
            grid-column: span 2;
        }
        .detail {
            grid-column-start: 2;
        }
    }
    .prod2 {
        // background: rgb(60, 158, 238);
        display: grid;
        grid-template: 1fr / 645px 1fr;

        .deco {
            grid-column: span 2;
            background: url('../../static/prod2-deco.jpg') no-repeat 50% 50%;
            background-size: cover;
            &:after {
                background: url('../../static/prod1-robot.png') no-repeat bottom
                    left;
                background-size: contain;
            }

            .buy {
                left: 75px;
            }
        }
        .detail {
            // grid-column-start: 2;
            // background-color: rgb(65, 48, 28);
            grid-column-end: 2;
        }
    }
}

.packsContainer {
    display: grid;
    margin: 120px 0;
    // min-height: 100vh;
    // grid-column-gap: 20px;
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
    padding: 0 10px;

    @include for-tablet-landscape-up {
        grid-template-columns: 1fr 1fr;
        padding: 0;
    }

    // @media screen and (max-width: 1290px) {

    //     grid-template-areas: 'C';

    // }

    h1 {
        font-size: 60px;
    }

    .deco {
        // background-color: purple;
        background: url('../../static/prod1-deco.jpg') no-repeat 50% 50%;
        background-size: cover;
        height: 140px;
        @include for-tablet-landscape-up {
            height: 340px;
        }
        position: relative;

        &:after {
            content: '';
            position: absolute;
            height: 250px;
            width: 350px;
            @include for-tablet-landscape-up {
                height: 509px;
                width: 411px;
            }

            background: url('../../static/prod2-robot.png') no-repeat bottom
                left;
            background-size: contain;
            bottom: 0px;
            right: 30%;
        }

        &.alt {
            background: url('../../static/prod2-deco.jpg') no-repeat 50% 50%;
            background-size: cover;
            &:after {
                background: url('../../static/prod1-robot.png') no-repeat bottom
                    left;
                background-size: contain;
            }

            .buy {
                left: 75px;
            }
        }

        .buy {
            position: absolute;
            bottom: 45px;
            right: 60px;
        }
    }

    .detail {
        h3 {
            @include font-light;
            font-size: 32px;
        }

        p {
            font-size: 20px;

            @include for-tablet-landscape-up {
                font-size: 24px;
                padding-right: 60px;
            }
        }
    }

    .product {
        display: grid;
        grid-template-columns: 1fr;
        // grid-row-start: 2;

        // moving the description up in smaller screens
        // @include for-tablet-landscape-up {
        //     grid-row-start: 1;
        // }

        @media screen and (min-width: 1300px) {
            grid-template-columns: 1fr 645px;
        }
        // padding: 0 20px;

        .detail {
            grid-column-start: 2;
        }

        &.premium {
            grid-row-start: 2;
            @include for-tablet-landscape-up {
                grid-row-start: unset;
            }
        }

        &.basic {
            @include for-tablet-landscape-up {
                // grid-row-start: 1;
            }

            @media screen and (min-width: 1300px) {
                grid-template-columns: 645px 1fr;
            }

            .detail {
                grid-column-start: 1;
            }
        }
    }
}
.advantagesWrapper {
    h4 {
        @include font-light;
        text-transform: uppercase;
        font-size: 28px;
        padding-bottom: 10px;
        border-bottom: 2px solid #273036;
    }
    .advantages {
        display: grid;
        grid-template: 1fr / 1fr;
        @include for-tablet-landscape-up {
            grid-template: 1fr / 1fr 1fr 1fr;
        }
        grid-column-gap: 80px;
        align-content: stretch;
        margin: 20px 0 60px 0;
        text-align: center;

        .item {
            padding: 20px;

            h5 {
                font-size: 22px;
                text-transform: uppercase;
                margin-top: 35px;
            }
        }
    }
}
