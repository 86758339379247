@import '~styles/config.scss';

.loginButton {
    // width: 200px;
    // height: 100px;
    padding: 8px 20px;
    margin: 15px;
    // background: #999;
    text-transform: uppercase;
    position: relative;
    font-size: 18px;
    letter-spacing: 1px;
    cursor: pointer;

    @include font-regular;

    &:hover {
        color: inherit;
    }

    &:before {
        position: absolute;
        content: '';
        width: 9px;
        height: 100%;
        top: 0%;
        left: 0px;
        // z-index: -1;
        border-left: 1px solid #8e8e8e;
        border-top: 1px solid #8e8e8e;
        border-bottom: 1px solid #8e8e8e;
        transition: width 0.6s;
        // transition: border-color 0.6s;
    }

    &:hover:before,
    &:hover:after {
        width: 20px;
        border-top-color: $brand-primary;
        border-bottom-color: $brand-primary;
    }
    &:hover:before {
        border-left-color: $brand-primary;
    }
    &:hover:after {
        border-right-color: $brand-primary;
    }

    &::after {
        position: absolute;
        content: '';
        width: 9px;
        height: 100%;
        right: -2px;
        bottom: 0%;
        // z-index: -1;
        border-right: 1px solid #8e8e8e;
        border-top: 1px solid #8e8e8e;
        border-bottom: 1px solid #8e8e8e;
        transition: width 0.6s;
        // transition: border-color 0.6s;
    }
}
