@import '../../../styles/config';

.pageWrapper {
    position: relative;

    &.default {
        background: #0e191f url('static/default-bg.jpg') top center;
        background-size: cover;
    }

    &.home {
        background: #0e191f url('static/starfield.jpg') top center;
    }

    &.store {
        background: #000;
    }

    .page {
        padding: 150px 0 100px 0;
        min-height: 100vh;

        @include for-tablet-landscape-up {
            padding: 250px 0 100px 0;
        }
    }
}
