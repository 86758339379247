@import '~styles/config';

.formWrapper {
    font-family: $font-thin;

    .title {
        grid-area: header;
        justify-self: end;
        margin-right: 80px;
    }

    .form {
        grid-area: C;

        .inner {
            // width: 630px;
            margin: 0 auto;
            padding: 40px 60px 80px;
            background-color: #1f2123;

            p {
                margin-bottom: 50px;
            }

            a {
                float: right;
                font-size: 18px;
                margin: 20px 0;
            }
        }
    }

    @include for-desktop-up {
        display: grid;
        grid-template-columns: 1fr 630px 1fr;
        grid-template-areas:
            'header     C       right'
            'header     bottom   right';

        .form .inner {
            padding: 40px 110px 80px;
        }
    }
}
