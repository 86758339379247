// Font settings

@mixin font-thin() {
    font-family: $font-thin, sans-serif;
    // font-weight: 200;
}

@mixin font-light() {
    font-family: $font-light, sans-serif;
    // font-weight: 300;
}

@mixin font-regular() {
    font-family: $font-regular, sans-serif;
    // font-weight: 400;
}

@mixin font-light-italic() {
    font-family: $font-light-italic, sans-serif;
    // font-weight: 300;
}

@mixin font-regular-italic() {
    font-family: $font-regular-italic, sans-serif;
    // font-weight: 400;
}

@mixin font-medium() {
    font-family: $font-medium, sans-serif;
    // font-weight: normal;
}

@mixin font-semibold() {
    font-family: $font-semibold, sans-serif;
    // font-weight: 500;
}

// Headline underline
@mixin underline($color: $brand-primary) {
    position: relative;
    display: inline-block;
    padding-bottom: 30px;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $color;
    }
}

@mixin sideline($width: 2px, $height: 44px) {
    position: relative;
    padding-left: 25px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: $width;
        height: $height;
        background: $brand-primary;
        top: 8px;
    }
}

// Useful for centering stuff inside relative container
@mixin centerer() {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin bgImg($position: center) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: $position;
}

@mixin fit() {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

@mixin container() {
    max-width: $max-1290;
    margin: 0 auto;
    padding: 0 10px;
}

// Offsets
@mixin offset-left($offset: 1) {
    margin-left: convertify($offset);
}

@mixin offset-right($offset: 1) {
    margin-right: convertify($offset);
}

// Helper to convert straight number to percentage
@function convertify($number) {
    @if type-of($number) == 'number' and unitless($number) {
        @return percentage($number / 12);
    }

    @return $number;
}

/*********************
BREAKPOINTS
*********************/

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}
@mixin for-tablet-portait-only {
    @media (min-width: 600px) and (max-width: 899px) {
        @content;
    }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}
@mixin for-tablet-landscape-only {
    @media (min-width: 900px) and (max-width: 1199px) {
        @content;
    }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}
@mixin for-desktop-only {
    @media (min-width: 1200px) and (max-width: 1799px) {
        @content;
    }
}

// To handle padding for screen when we shrink window to 1290px width, 20px padding on both sides
// @mixin for-content-max-width-up {
//     @media (min-width: 1310px) {
//         @content;
//     }
// }

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

// @mixin breakpoint($point) {
//     @if $point == desktop {
//         @media (min-width: 70em) {
//             @content;
//         }
//     } @else if $point == laptop {
//         @media (min-width: 64em) {
//             @content;
//         }
//     } @else if $point == tablet {
//         @media (min-width: 50em) {
//             @content;
//         }
//     } @else if $point == phablet {
//         @media (min-width: 37.5em) {
//             @content;
//         }
//     } @else if $point == mobileonly {
//         @media (max-width: 37.5em) {
//             @content;
//         }
//     }
// }
