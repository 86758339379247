@import '../../styles/config';

footer {
    background-color: #101010;
}

.footerContainer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    // max-width: 1290px;
    margin: 0 auto;
    padding: 60px 0px;
    // @include for-content-max-width-up {
    //     padding: 60px 0;
    // }

    h3 {
        // @include font-regular();
        text-transform: uppercase;
        font-size: 28px;
        margin: 0;
        padding: 0;
    }

    ul {
        padding: 0;
        padding-bottom: 20px;

        li {
            padding: 0;
            padding: 8px 0;
            letter-spacing: 0.05em;
            font-size: 16px;

            // should move to a more general place since we might use this elsewhere
            a {
                position: relative;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    height: 1px;
                    width: 0;
                }

                &:after {
                    right: 0;
                    background: rgba(234, 38, 103, 0.6);
                    // background: $brand-primary;
                    transition: width 0.35s ease;
                }

                &:hover {
                    color: #fff;
                    &:before {
                        width: 100%;
                        background: rgba(234, 38, 103, 0.6);
                        // background: $brand-primary;
                        transition: width 0.35s ease;
                    }
                    &:after {
                        width: 100%;
                        background: 0 0;
                        transition: all 0s ease;
                    }
                }
            }
        }

        li:first-child {
            @include font-regular();
            margin-bottom: 20px;
            font-size: 20px;
            text-transform: uppercase;
            color: #979797;
        }
    }

    .follow {
        grid-column: span 12;
        display: flex;
        align-items: baseline;
        margin-top: 15px;
        @include for-desktop-up {
            margin-bottom: 40px;
            grid-column: span 3;
        }
    }
    .social {
        margin: 15px 0;
        grid-column: span 12;
        justify-self: start;
        @include for-desktop-up {
            margin-bottom: 40px;
            grid-column: span 8;
        }
    }
    .vanguard {
        grid-column: span 12;
        @include for-tablet-landscape-up {
            grid-column: span 4;
        }
        @include for-desktop-up {
            grid-column: span 3;
        }
    }
    .universe {
        grid-column: span 12;
        @include for-tablet-landscape-up {
            grid-column: span 4;
        }

        @include for-desktop-up {
            grid-column: span 3;
        }
    }
    .info {
        grid-column: span 12;
        @include for-tablet-landscape-up {
            grid-column: span 4;
        }
        @include for-desktop-up {
            grid-column: span 6;
        }
    }
    .copyright {
        @include font-regular();
        color: #979797;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        grid-column: span 12;
        @include for-tablet-landscape-up {
            grid-column: span 6;
        }
    }
    .pegi {
        grid-column: span 12;
        justify-self: start;
        margin-top: 20px;
        @include for-tablet-landscape-up {
            margin-top: 0px;
            grid-column: span 6;
            justify-self: end;
        }
    }
}
