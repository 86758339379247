@import '../../../styles/config';

h2.title {
    position: relative;
    font-size: 28px;
    @include sideline(2px, 20px);

    &:after {
        top: 8px;
    }

    @include for-tablet-portrait-up {
        font-size: 40px;
        @include sideline(2px, 31px);

        &:after {
            top: 10px;
        }
    }

    @include for-tablet-landscape-up {
        font-size: 60px;
        @include sideline(2px, 45px);

        &:after {
            top: 17px;
        }
    }

    margin-bottom: 30px;
}
