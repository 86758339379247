@import '../../../../styles/config';

.product {
    display: grid;
    grid-template: 1fr / 1fr;
    margin-top: 40px;

    @include for-tablet-landscape-up {
        margin-top: 0;
    }

    @media screen and (min-width: 1500px) {
        grid-template: 1fr / 1fr 645px;
    }

    .deco {
        grid-column: span 2;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        height: 400px;
        background-position: right center;
        position: relative;
        z-index: 1;
        background-image: url('static/product-elysian-bg.png');
    
        .caption {
            display: none;
        }
    
        @include for-tablet-landscape-up {
            height: 355px;
        }
    
        @include for-desktop-up {
            .caption {
                display: block;
            }
        }
    
        @include for-big-desktop-up {
            // height: 22vw;
        }
    
        @include for-phone-only {
            background-position-x: 80%;
        }

        .character {
            position: absolute;
            bottom: 0;
            height: 470px;
            width: 100%;
            background-image: url('static/product-elysian-character.png');
            background-repeat: no-repeat;
            background-position: right bottom;
            z-index: 1;
        }

        .buy, .caption {
            position: absolute;
            bottom: 20px;
            z-index: 1;
        }
        
        .caption {
            font-size: 5vw;
            line-height: 1;
            text-transform: uppercase;
            word-spacing: 100vw;
            right: 60%;
            text-align: right;
        
            @include font-thin;

            @include for-big-desktop-up {
                font-size: 80px;
            }
        }
        
        .buy {
            left: 50%;
            transform: translateX(-50%);
        
            @include for-tablet-landscape-up {
                left: auto;
                right: 50px;
                transform: translate(0);
            }
        }
    }

    .detail {
        margin-top: 40px;
        padding-left: 20px;

        @include for-tablet-landscape-up {
            grid-column-start: 2;
        }

        @media screen and (min-width: 1500px) {
            padding-left: 0;
        }
    
        h3 {
            text-transform: uppercase;
            font-size: 32px;
    
            @include font-light;
            @include sideline(2px, 23px);
            padding-left: 15px;
        }
    
        p {
    
            @include for-tablet-landscape-up {
                padding-right: 60px;
            }
        }
        
        p, .link {
            font-size: 24px;
            padding: 0 15px;
        }
    
        .link, svg {
            transition: .2s ease-in-out;
        }
    
        .link {
            svg {
                color: $brand-primary;
                position: relative;
                top: 4px;
                font-size: 1.25em;
                margin-left: 8px;
            }
    
            &:hover {
                color: $brand-primary;
    
                svg {
                    filter: brightness(1.2);
                    transform: translateX(3px);
                }
            }
        }
    }
}

.product:first-of-type {
    .detail {
        h3:after {
            background: $brand-gold;
        }
    }

    .link {
        &:hover {
            color: $brand-gold;
        }

        svg {
            color: $brand-gold;
        }
    }
}

.product:last-child {
    @media screen and (min-width: 1500px) {
        grid-template: 1fr / 645px 1fr;
    }

    .deco {
        grid-column: span 2;
        background-position: left center;
        background-image: url('static/product-revenant-bg.png');

        @include for-phone-only {
            background-position-x: 20%;
        }

        .character {
            right: 0;
            background-image: url('static/product-revenant-character.png');
            background-position: left bottom;
            background-position-x: 4vw;
        }

        .buy {
            @include for-tablet-landscape-up {
                right: auto;
                left: 50px;
            }
        }

        .caption {
            left: 50%;
            text-align: left;
        }
    }
    .detail {
        // grid-column-start: 2;
        // background-color: rgb(65, 48, 28);
        // padding-left: 0;
        grid-column-end: 1;
    }
}
// }
