@import '../../../styles/variables';
@import '../../../styles/mixins';

$social-bg-color: #19232f;
// $social-icon-color: #8e8e8e;
$social-icon-color: #8e8e8e;

.socialIcon {
    // width: 32px;
    // height: 32px;
    // background: #8c8c8c;
    position: relative;
    // transition: 0.2s ease-in-out;
    transition: background-color 150ms ease-out;
    position: relative;
    // width: 40px;
    // height: 23.09px;
    background-color: $social-bg-color;
    // margin: 11.55px 0;
    display: inline-flex;
    margin-left: 20px;
    // Diamond effect
    // transform: rotate(45deg);
    // transform-origin: 0 100%;

    &:first-child {
        margin-left: 0;
    }

    // &:before,
    // &:after {
    //     content: '';
    //     position: absolute;
    //     width: 0;
    //     border-left: 20px solid transparent;
    //     border-right: 20px solid transparent;
    //     transition: border-color 150ms ease-out;
    // }

    // &:before {
    //     bottom: 100%;
    //     border-bottom: 11.55px solid $social-bg-color;
    // }

    // &:after {
    //     top: 100%;
    //     width: 0;
    //     border-top: 11.55px solid $social-bg-color;
    // }

    svg {
        margin-top: -3px;
    }

    &:hover {
        svg {
            color: #fff;
        }
    }

    // &.facebook:hover {
    //     background-color: $social-facebook;
    //     &:before,
    //     &:after {
    //         border-bottom-color: $social-facebook;
    //         border-top-color: $social-facebook;
    //     }
    // }

    // &.twitter:hover {
    //     background-color: $social-twitter;
    //     &:before,
    //     &:after {
    //         border-bottom-color: $social-twitter;
    //         border-top-color: $social-twitter;
    //     }
    // }

    // &.youtube:hover {
    //     background-color: $social-youtube;
    //     &:before,
    //     &:after {
    //         border-bottom-color: $social-youtube;
    //         border-top-color: $social-youtube;
    //     }
    // }

    // &.instagram:hover {
    //     background-color: $social-instagram;
    //     &:before,
    //     &:after {
    //         border-bottom-color: $social-instagram;
    //         border-top-color: $social-instagram;
    //     }
    // }

    // &.discord:hover {
    //     background-color: $social-discord;
    //     &:before,
    //     &:after {
    //         border-bottom-color: $social-discord;
    //         border-top-color: $social-discord;
    //     }
    // }
    a {
        // @include reset-anchor;

        position: absolute;
        width: 100%;
        height: 100%;

        svg {
            @include centerer;

            font-size: 21px;
            color: $social-icon-color;
            // transition: 0.2s ease-in-out;
        }
    }
}
